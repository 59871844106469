import { isMobileOnly } from "react-device-detect";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DashBoardDountChart = ({ data, title, socketData }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const defaultArray = [
    `${t("dashboard.Active")}`,
    `${t("dashboard.InActive")}`,
    `${t("filter.NON_OPERATIONAL")}`,
    // `${t("dashboard.Pending")}`,
  ];

  const defaultData = [
    socketData?.active,
    socketData?.inActive,
    socketData.non_operational,
    socketData.pending,
  ];
  const defaultColorData = [
    socketData?.activeColor,
    socketData?.InactiveColor,
    socketData?.non_operationalColor,
    socketData?.pendingColor,
  ];

  return (
    <div
      className="chartCard rounded-3 p-4 d-flex flex-column justify-content-between"
      // style={{ height: isMobileOnly ? "35vh" : "40vh" }}
    >
      <div>
        <h6 className=" fw-bold ">{title}</h6>
      </div>
      <div className=" d-flex justify-content-center">
        <div
          className={
            isMobileOnly ? " d-flex justify-content-center py-3" : "dount  py-3"
          }
        >
          <Doughnut
            data={{
              labels: socketData?.alarmColor
                ? [
                    ...defaultArray,
                    ...[
                      `${t("dashboard.Alarm")}`,
                      `${t("dashboard.Fault")}`,
                      `${t("dashboard.NA")}`,
                    ],
                  ]
                : socketData?.ivsm_na
                ? [...defaultArray, ...[`${t("dashboard.NA")}`]]
                : defaultArray,
              datasets: [
                {
                  label: " ",
                  data: socketData?.alarmColor
                    ? [
                        ...defaultData,
                        ...[
                          socketData?.alarm,
                          socketData?.fault,
                          socketData?.novastar_na,
                        ],
                      ]
                    : socketData?.ivsm_na
                    ? [...defaultData, ...[socketData?.ivsm_na]]
                    : defaultData,
                  backgroundColor: socketData?.alarmColor
                    ? [
                        ...defaultColorData,
                        ...[
                          socketData?.alarmColor,
                          socketData?.faultColor,
                          socketData?.novastar_naColor,
                        ],
                      ]
                    : socketData?.ivsm_naColor
                    ? [...defaultColorData, ...[socketData?.ivsm_naColor]]
                    : defaultColorData,
                  borderColor: socketData?.alarmColor
                    ? [
                        ...defaultColorData,
                        ...[
                          socketData?.alarmColor,
                          socketData?.faultColor,
                          socketData?.novastar_naColor,
                        ],
                      ]
                    : socketData?.ivsm_naColor
                    ? [...defaultColorData, ...[socketData?.ivsm_naColor]]
                    : defaultColorData,
                  // backgroundColor: ["#2ae1aa", "#ff9e3f"],
                  // borderColor: ["#2ae1aa", "#ff9e3f"],
                  borderWidth: 1,
                  // offset: 10,
                },
              ],
            }}
            options={{
              cutout: "70%",
              onClick: (event, chartElements) => {
                switch (title) {
                  case `${t("dashboard.Active & InActive BillBoards")}`:
                    if (chartElements[0]?.index === 0) {
                      navigate(
                        "/billboards?sort=-createdAt&limit=10&over_all=true&page=1"
                      );
                    } else if (chartElements[0]?.index === 1) {
                      navigate(
                        "/billboards?sort=-createdAt&limit=10&over_all=false&page=1"
                      );
                    } else if (chartElements[0]?.index === 2) {
                      navigate(
                        "/billboards?sort=-createdAt&limit=10&page=1&board_status=NON_OPERATIONAL"
                      );
                    } else if (chartElements[0]?.index === 3) {
                      navigate(
                        "/billboards?sort=-createdAt&limit=10&page=1&board_status=REQUEST"
                      );
                    }

                    break;
                  case `${t("dashboard.Team Viewer")}`:
                    if (chartElements[0]?.index === 0) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=online&novastar_status=null&ivms_status=null&is_ivms=null&is_novastar=null"
                      );
                    } else if (chartElements[0]?.index === 1) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=offline&novastar_status=null&ivms_status=null&is_ivms=null&is_novastar=null"
                      );
                    } else if (chartElements[0]?.index === 2) {
                      navigate(
                        "/billboards?sort=-createdAt&limit=10&page=1&board_status=NON_OPERATIONAL"
                      );
                    } else if (chartElements[0]?.index === 3) {
                      navigate(
                        "/billboards?sort=-createdAt&limit=10&page=1&board_status=REQUEST"
                      );
                    }
                    break;
                  case `${t("dashboard.Novastar")}`:
                    if (chartElements[0]?.index === 0) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=null&novastar_status=online&ivms_status=null&is_ivms=null&is_novastar=true"
                      );
                    } else if (chartElements[0]?.index === 1) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=null&novastar_status=offline&ivms_status=null&is_ivms=null&is_novastar=true"
                      );
                    } else if (chartElements[0]?.index === 2) {
                      navigate(
                        "/billboards?board_status=NON_OPERATIONAL&page=1&teamviewer_status=null&novastar_status=null&ivms_status=null&is_ivms=null&is_novastar=null"
                      );
                    } else if (chartElements[0]?.index === 3) {
                      navigate(
                        "/billboards?sort=-createdAt&limit=10&page=1&board_status=REQUEST"
                      );
                    } else if (chartElements[0]?.index === 4) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=null&novastar_status=alaram&ivms_status=null&is_ivms=null&is_novastar=true"
                      );
                    } else if (chartElements[0]?.index === 5) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=null&novastar_status=fault&ivms_status=null&is_ivms=null&is_novastar=true"
                      );
                    } else if (chartElements[0]?.index === 6) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=null&novastar_status=null&ivms_status=null&is_ivms=null&is_novastar=false"
                      );
                    }
                    break;
                  case `${t("dashboard.IVMS 4200")}`:
                    if (chartElements[0]?.index === 0) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=null&novastar_status=null&ivms_status=online&is_ivms=true&is_novastar=null"
                      );
                    } else if (chartElements[0]?.index === 1) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=null&novastar_status=null&ivms_status=offline&is_ivms=true&is_novastar=null"
                      );
                    } else if (chartElements[0]?.index === 2) {
                      navigate(
                        "/billboards?board_status=NON_OPERATIONAL&page=1&teamviewer_status=null&novastar_status=null&ivms_status=null&is_ivms=null&is_novastar=null"
                      );
                    } else if (chartElements[0]?.index === 3) {
                      navigate(
                        "/billboards?sort=-createdAt&limit=10&page=1&board_status=REQUEST"
                      );
                    } else if (chartElements[0]?.index === 4) {
                      navigate(
                        "/billboards?board_status=ACTIVE&page=1&teamviewer_status=null&novastar_status=null&ivms_status=null&is_ivms=false&is_novastar=null"
                      );
                    }
                    break;
                  default:
                    break;
                }
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DashBoardDountChart;
