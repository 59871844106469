// ImageComponent.jsx
import heic2any from "heic2any";
import { useState, useEffect } from "react";

const ImageComponent = ({ img, onClick, isPreview }: any) => {
  const [convertedImg, setConvertedImg] = useState(img);

  useEffect(() => {
    if (img.endsWith(".heic")) {
      fetch(img)
        .then((res) => res.blob())
        .then((blob) => heic2any({ blob, toType: "image/jpeg" }))
        .then((convertedBlob: any) => {
          const convertedUrl = URL.createObjectURL(convertedBlob);
          setConvertedImg(convertedUrl);
        })
        .catch((error) => console.error("HEIC conversion error:", error));
    }
  }, [img]);

  return (
    <img
      src={convertedImg}
      alt="attachment"
      onClick={onClick}
      className={isPreview ? "popup-img" : "embet"}
    />
  );
};

export default ImageComponent;
