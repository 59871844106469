import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import ImageComponent from "../ImageComponent";

export default function ImagePopUp(props: any) {
  const { t } = useTranslation();

  return (
    props?.imageShow &&
    props?.image && (
      <Modal
        show={props?.imageShow}
        onHide={() => {
          props?.setImageShow(false);
        }}
        centered={true}
        size="lg"
      >
        <Modal.Body>
          <div className=" d-flex justify-content-between align-items-center py-3">
            <h4 className=" fw-semibold m-0">{t("Image")}</h4>
            <div
              onClick={() => {
                props?.setImageShow(false);
              }}
              className=" pointer"
            >
              <RxCross2 fontSize={24} color="#b1b1b1" />
            </div>
          </div>
          <div className="row py-3">
            <div className="justify-content-center">
              <div className=" d-flex align-items-center justify-content-center">
                {props?.image?.includes(".heic") ? (
                  <ImageComponent img={props?.image} isPreview={true} />
                ) : (
                  <img src={props?.image} alt="" className="popup-img" />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  );
}
